import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  Button,
  Dropdown,
  Heading,
  Input,
  InputHelper,
  Page
} from "../components";
import { useStaticQuery, graphql } from "gatsby";
import { togglePopup } from "../utilities/index";

import "./SurePayAuthorizationForm.css";

const Recaptcha = require("react-recaptcha");

const SurePayAuthroizationForm: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      accountNumberSample: file(name: { eq: "hmc-account-number-sample" }) {
        publicURL
      }
      routingNumberSample: file(
        name: { eq: "hmc-routing-and-account-number-sample" }
      ) {
        publicURL
      }
    }
  `);

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const recaptchaVerifyCallback = response => {
    setCaptchaVerified(true);
    setCaptchaToken(response);
  };

  const recaptchaExpiredCallback = _response => {
    setCaptchaVerified(false);
    setCaptchaToken("");
  };

  return (
    <Page title="SurePay Authorization" layout="default">
      <form
        method="POST"
        encType="multipart/form-data"
        action={`${
          process.env.GATSBY_HMCMGT_API_GATEWAY_URL
        }/surepay-authorization`}
      >
        <Heading style={{ textAlign: "center" }} level={1}>
          SurePay Authorization Form
        </Heading>
        <p style={{ textAlign: "center" }}>
          If submitted by the 15th, SurePay will go into effect around the 10th
          of the following month.
        </p>
        <p style={{ textAlign: "center" }}>
          Note: If the 10th falls on a Saturday, your payment will be deducted
          on the 9th. If the 10th falls on a Sunday or holiday, your payment
          will be deducted on the next business day.
        </p>
        <p style={{ textAlign: "center" }}>
          <Button
            style={{ fontWeight: "normal" }}
            href="/tos"
            target="_blank"
            rel="noreferrer"
          >
            Terms &amp; Conditions <span aria-hidden="true">&rarr;</span>
          </Button>
        </p>

        <hr />

        <fieldset>
          <legend>Owner Details</legend>
          <div>
            <label htmlFor="first_name">First Name</label>
            <Input id="first_name" name="first_name" required />
          </div>

          <div>
            <label htmlFor="last_name">Last Name</label>
            <Input id="last_name" name="last_name" required />
          </div>

          <div>
            <label htmlFor="association_name">Association</label>
            <Input id="association_name" name="association_name" required />
          </div>

          <div>
            <label htmlFor="unit_number">Unit Number</label>
            <Input id="unit_number" name="unit_number" required />
          </div>

          <div>
            <label htmlFor="hawaiiana_account_number">
              Hawaiiana Account Number
            </label>
            <Input
              id="hawaiiana_account_number"
              name="hawaiiana_account_number"
              style={{ margin: "0" }}
              required
            />
            <InputHelper
              id="account-number-example"
              label="Need help locating your account number?"
              style={{ margin: "0 0 1rem 0" }}
              cb={togglePopup}
            >
              <img src={data.accountNumberSample.publicURL} />
            </InputHelper>
          </div>

          <div>
            <label htmlFor="phone_number">Phone Number</label>
            <Input id="phone_number" name="phone_number" type="tel" required />
          </div>

          <div>
            <label htmlFor="email_address">Email Address</label>
            <Input id="email_address" name="email_address" required />
          </div>
        </fieldset>
        <fieldset>
          <legend>Bank Account Information</legend>

          <div>
            <label htmlFor="financial_institution_name">
              Your Financial Institution
            </label>
            <Input
              id="financial_institution_name"
              name="financial_institution_name"
              required
            />
          </div>

          <div>
            <label htmlFor="routing_number">9-Digit Routing Number</label>
            <Input
              id="routing_number"
              name="routing_number"
              style={{ margin: "0" }}
              required
            />
            <InputHelper
              id="routing-number-example"
              label="Learn how to identify your financial institution’s 9 digit routing number"
              style={{ margin: "0 0 1rem 0" }}
              cb={togglePopup}
            >
              <img src={data.routingNumberSample.publicURL} />
            </InputHelper>
          </div>

          <div>
            <label htmlFor="bank_account_number">Account Number</label>
            <Input
              id="bank_account_number"
              name="bank_account_number"
              required
            />
          </div>

          <div>
            <label htmlFor="bank_account_type">Type of Account</label>
            <Dropdown
              id="bank_account_type"
              name="bank_account_type"
              defaultValue=""
              required
            >
              <option value="" disabled>
                Select One
              </option>
              <option value="checking">Checking</option>
              <option value="savings">Savings</option>
            </Dropdown>
          </div>

          <div>
            <label htmlFor="voided_check_file">
              Attach Voided Check or Bank Statement
            </label>
            <br />
            <Input
              type="file"
              id="voided_check_file"
              name="voided_check_file"
            />
          </div>
        </fieldset>
        <hr />
        <p style={{ textAlign: "center" }}>
          Please attach a voided check from your financial institution or
          statement for account verification. A request for SurePay cancellation
          must be received by the 15th of the month in order to take effect the
          following month.
        </p>
        <hr />
        <p>
          I authorize Hawaiiana Management Company, Ltd. (Hawaiiana), as Agent
          for my Association, to begin deductions from my account with the
          financial institution named in the Online Surepay Form for payment of
          my Association Charges. I also agree to payment of any handling fee
          charged for SurePay payment that cannot be processed due to
          insufficient funds, stop payment or account closure.
        </p>
        <p>
          If I wish to terminate my automated SurePay payment, I understand that
          I must:
          <ol style={{ listStyle: "decimal inside" }}>
            <li>
              Submit an{" "}
              <a href="/surepay-cancellation-form">Online Cancellation Form</a>{" "}
              to Hawaiiana OR
            </li>
            <li>Send a letter of notification to Hawaiiana.</li>
            <li>
              {`The Online Cancellation Form OR letter of notification must 
              be received by the 15th of the month to terminate service for 
              the following month; otherwise termination will take place after 
              the following month's payment. `}
            </li>
          </ol>
        </p>
        <p>
          If Hawaiiana Management Company, Ltd. wishes to terminate SurePay
          service, the company will send a letter of notification to me. This
          letter will be mailed 20 days prior to the next SurePay payment for
          the termination to be effective.
        </p>
        <p>
          <Button
            style={{
              fontWeight: "normal",
              whiteSpace: "initial",
              textAlign: "center"
            }}
            href="/electronic-communications-notice"
            target="_blank"
            rel="noreferrer"
          >
            View the Consent and Notice Regarding Electronic Communication{" "}
            <span aria-hidden="true">&rarr;</span>
          </Button>
        </p>

        <p>
          <div>
            <Input
              type="checkbox"
              id="agrees_to_electronic_communication"
              name="agrees_to_electronic_communication"
              required
            />
            <label htmlFor="agrees_to_electronic_communication">
              I agree to the terms and conditions in the &ldquo;Consent and
              Notice Regarding Electronic Communication&rdquo;.
            </label>
          </div>
        </p>
        <p>
          <div>
            <Input
              type="checkbox"
              id="agrees_to_terms_and_conditions"
              name="agrees_to_terms_and_conditions"
              required
            />
            <label htmlFor="agrees_to_terms_and_conditions">
              I agree to the Terms and Conditions
            </label>
          </div>
        </p>
        <p>
          Type your name in the *Signature Block*. By typing in your name, you
          are signing this form electronically and you agree your electronic
          signature is the legal equivalent of your manual signature on this
          form and that you are authorizing the changes you have made on this
          form.
        </p>
        <label htmlFor="electronic_signature">Signature</label>
        <Input id="electronic_signature" name="electronic_signature" required />
        <div id="recaptcha-container">
          {typeof window !== "undefined" && (
            <Recaptcha
              elementId="recaptcha-container"
              render="explicit"
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
              verifyCallback={recaptchaVerifyCallback}
              onloadCallback={console.debug}
              expiredCallback={recaptchaExpiredCallback}
            />
          )}
        </div>
        <Input
          id="captcha_token"
          name="captcha_token"
          type="hidden"
          value={captchaToken}
          required
        />

        {isCaptchaVerified && (
          <p style={{ textAlign: "center" }}>
            <Button type="submit">Submit</Button>
          </p>
        )}
      </form>
      <p style={{ textAlign: "center" }}>
        * If you wish to terminate SurePay payment, send a letter of
        notification to:
      </p>
      <address style={{ textAlign: "center" }}>
        Hawaiiana Management Company Ltd
        <br />
        Attention: Surepay
        <br />
        711 Kapiolani Boulevard, Suite 700 Honolulu, HI 96813
      </address>
      <p style={{ textAlign: "center" }}>
        <Button
          style={{ fontWeight: "normal" }}
          href="/surepay-cancellation-form"
        >
          Fill out the cancellation form online{" "}
          <span aria-hidden="true">&rarr;</span>
        </Button>
      </p>
    </Page>
  );
};

export default SurePayAuthroizationForm;
