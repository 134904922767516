export const togglePopup = id => {
  if (typeof document !== undefined) {
    const targetButton = document.getElementById(id);
    targetButton.classList.toggle("input-helper__button--visible");
    targetButton.classList.toggle("input-helper__button--hidden");
  }
};

export const toTitleCase = (str: string): string => {
  return str
    .split(" ")
    .map(word => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join(" ");
};
